import Lottie from "lottie-react"
import AnimationFailure from '@/icons/animations/Failure.json'
import { useHapticFeedback, useWebApp } from "@vkruglikov/react-telegram-web-app";
import { useState } from "react";
import { animationSizes } from '@/constants'
import { useTranslation } from 'react-i18next';

export default function ErrorComponent(props) {
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const { t } = useTranslation()
	const errorsTexts = [405, 410, 423, 426]
	const [status, setStatus] = useState('default')
	const WebApp = useWebApp();

	const buttons = {
		426: () => WebApp.openTelegramLink(process.env.REACT_APP_LINK_TO_BOT + '?start=subscription')
	}

	useState(() => {
		notificationOccurred('error')
		if (props.error && props.error.response && props.error.response.status) {
			if (errorsTexts.includes(props.error.response.status)) {
				setStatus(props.error.response.status)
			}
		}
	}, [])

	return (
		<main>
			<div className='boxIntro'>
				<div className='boxIntro_sticker'>
					<Lottie animationData={AnimationFailure} loop={false} style={animationSizes['AnimationFailure']} />
				</div>
				<div className='boxIntro_title'>{ t('errors.' + status + '.title') }</div>
				<div className='boxIntro_text'>{ t('errors.' + status + '.text') }</div>
				<div className='boxIntro_text'>{ t('errors.' + status + '.text2') }</div>
				{buttons[status] ?
					<button className='btn btn--tt-none btn-block' onClick={buttons[status]}>{ t('errors.' + status + '.btn') }</button>
				: null}
			</div>
		</main>
    );
};
