import api from '@/api'

export default class MemberApi {
	static async create(data) {
		const http = api();
		return http.post('/member/make', data).then(function (response) {
			return response.data
		})
	}

	static async check(data) {
		const http = api();
		return http.post('/member/check', data).then(function (response) {
			return response.data
		})
	}

	static async toBot(data) {
		const http = api();
		return http.post('/member/to-bot', data).then(function (response) {
			return response.data
		})
	}

	static async share(data) {
		const http = api();
		return http.post('/member/share', data).then(function (response) {
			return response.data
		})
	}

	static async buy(data) {
		const http = api();
		return http.post('/member/buy', data).then(function (response) {
			return response.data
		})
	}

	static async get(data) {
		const http = api();
		return http.post('/member/get', data).then(function (response) {
			return response.data
		})
	}
}
