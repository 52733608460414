import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import Page from '@/components/Page';
import { setUser } from '@/modules/User/reducer';
import { setRaffle } from '@/features/Raffle/reducer';
import HomeApi from '../api';
import Member from '@/modules/Member';
import Load from '@/components/Load';
import useEnvInitData from '@/hooks/useEnvInitData'
import UserNotAccessPm from '@/modules/User/components/NotAccessPm';
import { useTranslation } from 'react-i18next';

export default function HomePage() {
	const { t } = useTranslation()
	const params = useParams()
	const dispatch = useDispatch()
	const { showBoundary } = useErrorBoundary()
	const [InitDataUnsafe, InitData] = useEnvInitData();

	const [loading, setLoading] = useState(true)
	const [accessPm, setAccessPm] = useState(InitDataUnsafe.user.allows_write_to_pm)
	const navigate = useNavigate();

	let param = '0'
	if (InitDataUnsafe.start_param) {
		param = InitDataUnsafe.start_param
	} else if (params.raffle) {
		param = params.raffle
	}

	useEffect(() => {
		if (param.substring(0, 3) === 'pay') {
			let data = param.substring(3).split('-')
			let type = data[0] ?? 1
			let period = data[1] ?? 1
			navigate('/payment/' + type + '/' + period);
		} else if (param.substring(0, 3) === 'tnf') {
			let data = param.substring(3).split('-')
			let type = data[0] ?? 1
			let period = data[1] ?? 1
			navigate('/tinkoff/' + type + '/' + period);
		} else {
			if (accessPm) {
				HomeApi.init({
					initData: InitData,
					param: param
				}).then(function(result) {
					setLoading(false)
					if (result.user && result.raffle) {
						dispatch(setUser(result.user))
						dispatch(setRaffle(result.raffle))
					} else {
						showBoundary()
					}
				}).catch((e) => {
					setLoading(false)
					showBoundary(e)
				})
			}
		}
	}, [accessPm])

	return (
    	<Page title={t('features.home.title')}>
			{!accessPm ?
				<UserNotAccessPm callback={() => setAccessPm(true)} />
			: loading ?
				<Load/>
			:
				<Member/>
			}
	    </Page>
  	);
}
